import React from "react";
import BootstrapTable, { ColumnDescription, PaginationOptions, SortOrder } from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from "react-bootstrap-table2-paginator";
import strings from '../../literales/ficherosStrings.json';
import APIConnect from "../API/APIConnect";
import APIUrls from "../API/APIUrls";
import Cadenas from "../Common/Cadenas";
import ComponentesListas from "../Common/ComponentesListas";
import Fechas from "../Common/Fechas";
import { APIErrorColumnNames, IAPIError, IAPIErrores } from "../Models/APIErrores";

//import errorData from '../../exampleData/errorData.json';

export interface IListaErroresProps {
    usuario: string;
    fichero: string;
    fecha: string;
}

interface IListaErroresState {
    errorList: IAPIError[];
    loadingData: boolean;
}

export default class ListaArchivos extends React.Component<IListaErroresProps, IListaErroresState> {
    constructor(props: IListaErroresProps) {
        super(props);
        this.state = {
            errorList: [],
            loadingData: true
        };
    }

    private timerRecargaLista: number | undefined = undefined;

    public componentDidMount() {
        this.loadData();
        this.timerRecargaLista = window.setInterval(() => {
            this.loadData();
        }, 180000); //180segundos
    }

    public componentDidUpdate(prevProps: IListaErroresProps) {
        if (prevProps.fichero !== this.props.fichero
            || prevProps.usuario !== this.props.usuario
            || prevProps.fecha !== this.props.fecha) {
            this.loadData();
        }
    }

    public componentWillUnmount() {
        window.clearInterval(this.timerRecargaLista);
    }

    private filterColumns: ColumnDescription<any, any>[] = [
        {
            dataField: APIErrorColumnNames.Columna,
            text: strings.columnaColumna,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroColumna })
        }, {
            dataField: APIErrorColumnNames.Descripcion,
            text: strings.columnaDescripcion,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroDescripcion })
        }, {
            dataField: APIErrorColumnNames.DetalleError,
            text: strings.columnaDetalleError,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroDetalleError })
        }, {
            dataField: APIErrorColumnNames.EstatusGlobal,
            text: strings.columnaEstatus,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroEstatus })
        }, /*{
            dataField: APIErrorColumnNames.Fichero,
            text: strings.columnaFichero,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroFichero }),
            style: { overflow: "auto" }
        }, */{
            dataField: APIErrorColumnNames.LineasError,
            text: strings.columnaLineasError,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroLineasError })
        }, {
            dataField: APIErrorColumnNames.TipoError,
            text: strings.columnaTipo,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroTipo })
        }, {
            dataField: APIErrorColumnNames.Plantilla,
            text: strings.columnaTemplate,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroTemplate })
        }
    ];

    private defaultSorted: [{ dataField: any; order: SortOrder; }] | undefined = [{
        dataField: APIErrorColumnNames.ID,
        order: "desc"
    }];

    private paginationOptions: PaginationOptions = {
        showTotal: true,
        onPageChange: ((page: number, sizePerPage: number) => {
        }),
        paginationTotalRenderer: ((from: number, to: number, size: number): JSX.Element => {
            return ComponentesListas.paginacionTotales(this.state.loadingData, from, to, size);
        })
    };

    public render() {
        return (
            <>
                <div className="table">
                    <h3 className="title">{strings.tituloListaErrores}</h3>
                    <BootstrapTable
                        keyField={APIErrorColumnNames.ID}
                        columns={this.filterColumns}
                        data={this.state.errorList}
                        filter={filterFactory()}
                        defaultSorted={this.defaultSorted}
                        hover={true}
                        pagination={paginationFactory(this.paginationOptions)}
                    />
                </div>
                <div className="tableSeparator"></div>
            </>
        );
    }

    private loadData(): void {
        /* Testing with example Data */
        //this.filterLoadedData(errorData);
        //return;

        this.setState({
            loadingData: true
        }, this.getAPIData);
    }

    private getAPIData() {
        const url: string = APIUrls.getFileErrorEndpointUrl();
        const query: string = `?file_id=${this.props.usuario}`;

        APIConnect.Post<IAPIErrores>(url + query, 500).then((responseValue) => {
            this.filterLoadedData(responseValue.files);
        }, (rejectError) => {
            console.log(`Error API errores: ${rejectError}`);
            this.setState({ loadingData: false });
        });
    }

    private filterLoadedData(data: IAPIError[]) {
        let filteredResults: IAPIError[] = [];
        if (!!this.props.usuario && !!this.props.fecha && !!this.props.fichero) {
            let escapedUsername = Cadenas.escapeRegExp(this.props.usuario.toLowerCase());
            let escapedDate = Fechas.formateaTextoFechaHoraFichero(this.props.fecha);
            let escapedFilename = Cadenas.escapeRegExp(this.props.fichero.toLowerCase());

            //Expresión utilizado como filtro, concatenación de la tupla de 3 valores, con un dígito opcional al final
            let regexFilter = new RegExp(`${escapedUsername}_${escapedDate}_${escapedFilename}\\d?$`);

            filteredResults = data.filter(x => regexFilter.test(x.id.toLowerCase()))

            if (filteredResults.length === 0) {
                console.log(`No results for regex: ${regexFilter.source}`);
            }
        }
        this.setState({
            errorList: filteredResults,
            loadingData: false
        });
    }

}