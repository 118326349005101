/**
 * API call returns JSON
 */
export interface IAPIErrores {
    files: IAPIError[];
}

/**
 * Entry of array "files"
 */
export interface IAPIError {
    id: string,
    columna: number,
    descripcion: string,
    detalle_error: string,
    estatusglobal: string,
    fichero: string,
    lineas_detectadas_error_top10: string,
    tipo_error: string,
    nombretemplate: string
}

/**
 * Text representation of IAPIError properties
 */
export enum APIErrorColumnNames {
    ID = "id",
    Columna = "columna",
    Descripcion = "descripcion",
    DetalleError = "detalle_error",
    EstatusGlobal = "estatusglobal",
    Fichero = "fichero",
    LineasError = "lineas_detectadas_error_top10",
    TipoError = "tipo_error",
    Plantilla = "nombretemplate"
}