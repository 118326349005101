import React from 'react';
import LoginForm from './LoginForm';
import Navigation, { MenuItem } from './Navigation';
import ImportarFicheros from './Ficheros/ImportarFicheros';
import Calendario from './Calendario/Calendario';
import Sesion from './Common/Sesion';
import { Auth, Hub } from "aws-amplify";
//import { Base64 } from 'js-base64';

interface IAppState {
  authenticatedUser: string | null;
  currentMenuItem: MenuItem;
}

const sessionKey: string = "NG.GetAuthUser";

export default class App extends React.Component<{}, IAppState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      authenticatedUser: Sesion.getSessionState(sessionKey),
      currentMenuItem: MenuItem.ImportarFicheros
    };
  }

  public componentDidMount() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("EVENT: " + event + ";DATA:" + data);
      switch (event) {
        case "signIn":
          console.log("EVENT: " + event + ";DATA:" + data);
          if (data.username != "") {
            let user = data.signInUserSession.idToken.payload.email.split("@")[0]
            this.setState({ authenticatedUser: user });
            Sesion.setSessionState(sessionKey, user);
            console.log("user: " + user)
          } else {
            console.log("no logged")
          }
          break;
        case "signOut":
          console.log("SignOut");
          this.setState({ authenticatedUser: null });
          break;
        case 'customOAuthState':
          //const prevUrl = Base64.decode(decodeURIComponent(data));
          console.log("PREV URL HUB: " + data);
          //this.setState({ prevLocation: prevUrl });
          break;
        default:
          console.log("default");
          break;
      }
    });
    //this.checkUser();
  }

  render() {
    return this.state.authenticatedUser ? (
      <div className="app-screen">
        {/* top branding row - content column spans all 3 rows */}
        <div className="app-topleft-branding"></div>
        <div className="app-content">

          {/* Menú */}
          <Navigation
            onMenuItemChange={(item: MenuItem) => { this.setState({ currentMenuItem: item }) }}
          />
          <br />

          {/* Area Importar ficheros */}
          <div hidden={this.state.currentMenuItem !== MenuItem.ImportarFicheros}>
            <ImportarFicheros authenticatedUser={this.state.authenticatedUser} />
          </div>

          {/* Area Calendario */}
          <div hidden={this.state.currentMenuItem !== MenuItem.Calendario}>
            <Calendario authenticatedUser={this.state.authenticatedUser} />
          </div>
          
        </div>
        <div className="app-fill">
          <div className="app-fill-top-branding"></div>
        </div>
        {/* middle branding row */}
        <div className="app-left-branding"></div>
        <div className="app-fill"></div>
        {/* bottom branding row */}
        <div className="app-bottomleft-branding"></div>
        <div className="app-fill"></div>
      </div>
    ) : (
      <div className="login-screen">
        <div />
        <div className="login-area">  {/* 3 columnas, centro con background */}
          <div />
          <LoginForm onAuthentication={(user: string) => {
            this.setState({ authenticatedUser: user });
            Sesion.setSessionState(sessionKey, user);
          }} />
          <div />
        </div>
        <div />
      </div>
    );
  }
}