import React, { CSSProperties } from "react";
import BootstrapTable, { ColumnDescription, PaginationOptions, SelectRowProps, SortOrder } from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from "react-bootstrap-table2-paginator";
import strings from '../../literales/ficherosStrings.json';
import APIConnect from "../API/APIConnect";
import APIUrls from "../API/APIUrls";
import ComponentesListas from "../Common/ComponentesListas";
import { APIArchivoColumnNames, IAPIArchivo, IAPIArchivos } from "../Models/APIArchivos";

//import fileData from '../../exampleData/fileData.json';

export interface IListaArchivosProps {
    onUserSelected: (user: string, date: string, fichero: string) => void;
    contadorSubida: number;
}

interface IListaArchivosState {
    userList: IAPIArchivo[];
    loadingData: boolean;
}

const fondoResaltado: string = 'grey';

export default class ListaArchivos extends React.Component<IListaArchivosProps, IListaArchivosState> {
    constructor(props: IListaArchivosProps) {
        super(props);
        this.state = {
            userList: [],
            loadingData: false
        };
    }

    private selectedRows: number[] = [];
    private timerRecargaLista: number | undefined = undefined;

    public componentDidMount() {
        this.loadData();
        this.timerRecargaLista = window.setInterval(() => {
            this.loadData();
        }, 30000); //30segundos
    }

    public componentDidUpdate(prevProps: IListaArchivosProps) {
        if (prevProps.contadorSubida > 0 && this.props.contadorSubida === 0) {
            this.loadData();
        }
    }

    public componentWillUnmount() {
        window.clearInterval(this.timerRecargaLista);
    }

    private filterColumns: ColumnDescription<any, any>[] = [
        {
            dataField: APIArchivoColumnNames.Usuario,
            text: strings.columnaUsuario,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroUsuario })
        },
        {
            dataField: APIArchivoColumnNames.Fichero,
            text: strings.columnaFichero,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroFichero })
        },
        {
            dataField: APIArchivoColumnNames.Tipo,
            text: strings.columnaTipo,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroTipo })
        },
        {
            dataField: APIArchivoColumnNames.FechaSubida,
            text: strings.columnaFechaSubida,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroFechaSubida })
        },
        {
            dataField: APIArchivoColumnNames.EstadoValidacion,
            text: strings.columnaEstado,
            sort: true,
            filter: textFilter({ placeholder: strings.filtroEstado })
        },
    ];

    private defaultSorted: [{ dataField: any; order: SortOrder; }] | undefined = [{
        dataField: APIArchivoColumnNames.FechaSubida,
        order: "desc"
    }];

    private rowStyle = (row: any, rowIndex: number): CSSProperties => {
        return {
            backgroundColor: this.selectedRows.includes(row.id) ? fondoResaltado : 'inherit'
        } as CSSProperties;
    };

    private selectrow: SelectRowProps<any> = {
        clickToSelect: true,
        mode: 'radio',
        bgColor: (row: any, rowIndex: number): string => {
            return fondoResaltado;  // return a color code
        },
        //selected: this.selectedRows,
        onSelect: (row: any) => {
            let archivo: IAPIArchivo | undefined = this.state.userList.find(x => x.id === row.id);
            if (archivo !== undefined) {
                this.props.onUserSelected(archivo.usuario, archivo.fecha_subida, archivo.fichero);
                if (!this.selectedRows.includes(row.id)) {
                    this.selectedRows = [row.id];
                }
            }
        }
    };

    private paginationOptions: PaginationOptions = {
        showTotal: true,
        onPageChange: ((page: number, sizePerPage: number) => {
        }),
        paginationTotalRenderer: ((from: number, to: number, size: number): JSX.Element => {
            return ComponentesListas.paginacionTotales(this.state.loadingData, from, to, size);
        })
    };

    public render() {
        return (
            <>
                <div className="table">
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 50 }}>
                            <h3 className="title">{strings.tituloListaArchivos}</h3>
                        </div>
                        <div style={{ color: 'red' }}>
                            <h5>
                                {
                                    this.props.contadorSubida > 0 &&
                                    <div>{strings.ProcesandoFicheros.replace("{0}", this.props.contadorSubida.toString())}</div>
                                }
                            </h5>
                        </div>
                    </div>
                    <BootstrapTable
                        keyField={APIArchivoColumnNames.ID}
                        columns={this.filterColumns}
                        data={this.state.userList}
                        filter={filterFactory()}
                        defaultSorted={this.defaultSorted}
                        hover={true}
                        selectRow={this.selectrow}
                        rowStyle={this.rowStyle}
                        pagination={paginationFactory(this.paginationOptions)}
                    />
                </div>
                <div className="tableSeparator"></div>

                { /* uncomment to refresh manually
                    <a href="#" onClick={() => this.loadData()}>Recarga</a>
                */ }
            </>
        );
    }

    private loadData(): void {
        /* Testing with example Data */
        //this.setState({ userList: fileData, loadingData: false });
        //return;

        this.setState({
            loadingData: true
        }, this.getAPIData);
    }

    private getAPIData() {
        const url: string = APIUrls.getFileImportsEndpintUrl();
        APIConnect.Post<IAPIArchivos>(url, 500).then((responseValue) => {
            this.setState({
                userList: responseValue.files,
                loadingData: false
            });
        }, (rejectError) => {
            console.log(`Error API archivos: ${rejectError}`);
            this.setState({ loadingData: false });
        });
    }


}
